exports.components = {
  "component---src-templates-article-tsx": () => import("./../../../src/templates/Article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-disclaimer-tsx": () => import("./../../../src/templates/Disclaimer.tsx" /* webpackChunkName: "component---src-templates-disclaimer-tsx" */),
  "component---src-templates-image-bank-tsx": () => import("./../../../src/templates/ImageBank.tsx" /* webpackChunkName: "component---src-templates-image-bank-tsx" */),
  "component---src-templates-ir-section-tsx": () => import("./../../../src/templates/IrSection.tsx" /* webpackChunkName: "component---src-templates-ir-section-tsx" */),
  "component---src-templates-not-found-error-tsx": () => import("./../../../src/templates/NotFoundError.tsx" /* webpackChunkName: "component---src-templates-not-found-error-tsx" */),
  "component---src-templates-office-list-tsx": () => import("./../../../src/templates/OfficeList.tsx" /* webpackChunkName: "component---src-templates-office-list-tsx" */),
  "component---src-templates-office-page-tsx": () => import("./../../../src/templates/OfficePage.tsx" /* webpackChunkName: "component---src-templates-office-page-tsx" */),
  "component---src-templates-person-list-page-tsx": () => import("./../../../src/templates/PersonListPage.tsx" /* webpackChunkName: "component---src-templates-person-list-page-tsx" */),
  "component---src-templates-press-release-list-tsx": () => import("./../../../src/templates/PressReleaseList.tsx" /* webpackChunkName: "component---src-templates-press-release-list-tsx" */),
  "component---src-templates-press-release-tsx": () => import("./../../../src/templates/PressRelease.tsx" /* webpackChunkName: "component---src-templates-press-release-tsx" */),
  "component---src-templates-search-tsx": () => import("./../../../src/templates/Search.tsx" /* webpackChunkName: "component---src-templates-search-tsx" */),
  "component---src-templates-start-page-tsx": () => import("./../../../src/templates/StartPage.tsx" /* webpackChunkName: "component---src-templates-start-page-tsx" */)
}

